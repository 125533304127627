/* Règles globales CSS */

/* Changement du style de l'autofill */
input:-webkit-autofill {
  transition-delay: 9999s;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(200, 200, 200, 0.2);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c0c7;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

html {
  scroll-behavior: smooth;
}

button {
  background-color: transparent;
  text-align: start;
  justify-content: flex-start;
  align-items: flex-start;
  border: 0px;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
}
