@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 300;
  src: url('fonts/Lato-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 100;
  src: url('fonts/Lato-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 700;
  src: url('fonts/Lato-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-weight: 900;
  src: url('fonts/Lato-Black.ttf') format('truetype');
}
